import {
  type Currency,
  type Language,
  Currency as CurrencyEnum,
  MeasurementSystem,
  replaceLocaleInPath,
} from '@ev/search-modules-api';
import { type CardProps, Box } from '@mui/material';
import omit from 'lodash-es/omit';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import type { HeaderProps } from '@/components/common/Header/Header';
import type {
  SelectItem,
  SelectProps,
} from '@/components/common/Select/Select';
import { SelectField } from '@/components/common/Select/SelectField';
import { mapLanguageCode } from '@/core/const/settingsConfig';
import { useSettings } from '@/core/hooks/useSettings';
import { CurrencyNameEnum } from '@/core/types/store.types';

import { SelectableRoundButton } from '../Button/Button';
import {
  StyledCard,
  StyledCardContent,
  StyledLabel,
  StyledTitle,
} from './GlobalSettings.styles';

const LanguageSelector = (): React.ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const router = useRouter();
  const languageCode = omit(mapLanguageCode, ['default']);

  const supportedLanguages: SelectItem[] = Object.values(languageCode).map(
    (lang) => ({
      name: t(lang.translationKey),
      value: lang.value,
    })
  );

  function changeLanguage(newLanguage: Language) {
    const targetUrl = replaceLocaleInPath(
      location.pathname + location.search,
      newLanguage
    );
    router.push(targetUrl);
  }

  return (
    <SelectField
      SelectProps={
        {
          values: supportedLanguages,
          value: language,
          onValueChange: (newLanguage) =>
            changeLanguage(newLanguage.value as Language),
        } as SelectProps
      }
      label={
        supportedLanguages.find((lang) => lang.value === language)?.name ?? ''
      }
      dataCy="languageSelect"
    />
  );
};

const CurrencySelector = (): React.ReactElement => {
  const { currency, setSettings } = useSettings();
  const { t } = useTranslation('common');

  const currencies: SelectItem[] = [
    {
      name: CurrencyEnum.EUR,
      value: CurrencyEnum.EUR,
      extraInfo: t(CurrencyNameEnum.EUR),
    },
    {
      name: CurrencyEnum.USD,
      value: CurrencyEnum.USD,
      extraInfo: t(CurrencyNameEnum.USD),
    },
    {
      name: CurrencyEnum.CAD,
      value: CurrencyEnum.CAD,
      extraInfo: t(CurrencyNameEnum.CAD),
    },
    {
      name: CurrencyEnum.GBP,
      value: CurrencyEnum.GBP,
      extraInfo: t(CurrencyNameEnum.GBP),
    },
    {
      name: CurrencyEnum.CHF,
      value: CurrencyEnum.CHF,
      extraInfo: t(CurrencyNameEnum.CHF),
    },
    {
      name: CurrencyEnum.AED,
      value: CurrencyEnum.AED,
      extraInfo: t(CurrencyNameEnum.AED),
    },
    {
      name: CurrencyEnum.CLF,
      value: CurrencyEnum.CLF,
      extraInfo: t(CurrencyNameEnum.CLF),
    },
    {
      name: CurrencyEnum.COP,
      value: CurrencyEnum.COP,
      extraInfo: t(CurrencyNameEnum.COP),
    },
    {
      name: CurrencyEnum.CZK,
      value: CurrencyEnum.CZK,
      extraInfo: t(CurrencyNameEnum.CZK),
    },
    {
      name: CurrencyEnum.DKK,
      value: CurrencyEnum.DKK,
      extraInfo: t(CurrencyNameEnum.DKK),
    },
    {
      name: CurrencyEnum.HUF,
      value: CurrencyEnum.HUF,
      extraInfo: t(CurrencyNameEnum.HUF),
    },
    {
      name: CurrencyEnum.MUR,
      value: CurrencyEnum.MUR,
      extraInfo: t(CurrencyNameEnum.MUR),
    },
    {
      name: CurrencyEnum.ZAR,
      value: CurrencyEnum.ZAR,
      extraInfo: t(CurrencyNameEnum.ZAR),
    },
  ];

  return (
    <SelectField
      SelectProps={
        {
          values: currencies,
          value: currency,
          onValueChange: (newCurrency) =>
            setSettings({ currency: newCurrency.value as Currency }),
        } as SelectProps
      }
      label={currency}
      dataCy="currencySelect"
    />
  );
};

type GlobalSettingsType = HeaderProps & {
  selectedValues?: string[];
} & CardProps;

export const GlobalSettings = ({
  onLanguageChange,
  ...props
}: GlobalSettingsType): React.ReactElement => {
  const { t } = useTranslation();
  const { setSettings, measurementSystem } = useSettings();

  return (
    <StyledCard elevation={1} {...props}>
      <StyledCardContent>
        <StyledTitle variant="h3" component="span">
          {t('search.globalSettings.settings')}
        </StyledTitle>
        <Box
          sx={{
            mb: { xs: 0, md: 1 },
          }}
        >
          <StyledLabel variant="h6" component="label">
            {t('search.globalSettings.preferredLang')}
          </StyledLabel>
        </Box>

        <LanguageSelector />

        <Box
          sx={{
            mb: { xs: 0, md: 1 },
            mt: 3,
          }}
        >
          <StyledLabel variant="h6" component="label">
            {t('search.globalSettings.preferredCurrency')}
          </StyledLabel>
        </Box>

        <CurrencySelector />

        <Box
          sx={{
            mb: 1,
            mt: 4,
          }}
        >
          <StyledLabel variant="h6">
            {t('search.globalSettings.preferredUnit')}
          </StyledLabel>
        </Box>

        <SelectableRoundButton
          selected={measurementSystem === MeasurementSystem.metric}
          onClick={() =>
            setSettings({
              measurementSystem: MeasurementSystem.metric,
            })
          }
          size="small"
          data-cy="unitMTButton"
        >
          {t('search.globalSettings.unitM')}
        </SelectableRoundButton>
        <SelectableRoundButton
          selected={measurementSystem === MeasurementSystem.imperial}
          onClick={() =>
            setSettings({
              measurementSystem: MeasurementSystem.imperial,
            })
          }
          size="small"
          data-cy="unitFTButton"
        >
          {t('search.globalSettings.unitFt')}
        </SelectableRoundButton>
      </StyledCardContent>
    </StyledCard>
  );
};
